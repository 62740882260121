html {
  letter-spacing: -0.8px;
  scroll-behavior: smooth;
  font-size: 0.9rem;
}


body {
  width: 100vw;
  background-color: var(--primary-color);
  color: var(--text-color);
  overflow-x: hidden;
}

// ----------------- RESETS
h1, h2, h3, h4, h5, h6 {
  letter-spacing: -1.3px;
  margin: 0;
}

p {
  font-weight: 400;
  margin: 0;
  color: var(--secondary-text-color);
}

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0
}

a {
  text-decoration: none;
}

input, select, textarea {
  background-color: var(--primary-color) !important;
  color: var(--text-color) !important;
  border: 1px solid var(--secondary-text-color) !important;

  &::placeholder {
    color: var(--secondary-text-color) !important;
    opacity: .8 !important;
  }
}
