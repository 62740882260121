// /* Scrollbar para Firefox */
// * {
//   scrollbar-width: thin;     /* Ancho del scrollbar */
//   scrollbar-color: var(--primary-color) var(--text-color); /* Color del thumb y track */
// }


/* Scrollbar global - Webkit-based browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 5px;       /* Ancho del scrollbar */
  &:hover {
    width: 4px;
  }               
}

::-webkit-scrollbar-track {
  background: var(--primary-color);       /* Color de fondo del track */
}

::-webkit-scrollbar-thumb {
  background-color: var(--text-color);    /* Color del thumb (parte que se mueve) */
  border-radius: 10px;       /* Bordes redondeados */
  border: 1px solid var(--text-color); /* Espacio entre el thumb y el track */
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--secondary-color);    /* Color al pasar el mouse */
}
/* Opcional: Personalizar el scrollbar en Internet Explorer y Edge antiguos */
// body {
//   -ms-overflow-style: none;  /* Oculta el scrollbar */
// }

// /* Para sistemas Windows o sistemas donde desees que no se muestre el scrollbar */
body::-webkit-scrollbar {
  display: none;
}
