.sticky-component {
  position: sticky;
}

.glass-segment {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px) brightness(var(--brightness-glass-segment));  // Desenfoque y brillo
  margin: 1.5rem 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  // Sombra suave
  animation: slideUp 1s ease-out forwards;

  @media (max-width: 1199px) {
    margin: 1rem 0;
  }

  @media (min-width: 576px) {
    border-radius: 5px;
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(40px); /* Comienza un poco más abajo */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Termina en su posición original */
  }
}

.flex-md-column .nav-link {
  padding: 0.03rem 0.2rem;
}

.dropdown {
  z-index: 4000;
  overflow-y: hidden;
  background-color: var(--text-color);
  color: var(--primary-color);
  border-radius: 5px;
  max-height: 0;
  animation: dropdownSlideDown 1s ease forwards;

  @media (max-width: 430px) {
    max-width: 94vw;
    overflow-x: hidden;
  }
}

@keyframes dropdownSlideDown {
  0% {
    max-height: 0;
    overflow-y: hidden;
  }
  100% {
    max-height: 20rem;
    overflow-y: auto;
  }
}

input + .dropdown {
  min-width: 12.5rem;
}

.dropdown-item:hover {
  @media (hover: hover) {
    background-color: var(--primary-color);
    color: var(--text-color);
  }
}
.dropdown-item {
  padding: 0.1rem 0.3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.flex-column ul ul {
  position: relative;
  color: var(--primary-color);
  background-color: var(--text-color);

  & .dropdown-item:hover {
    @media (hover: hover) {
      background-color: var(--primary-color);
      color: var(--text-color);
    }
  }
}

.navbar-expand-md ul ul {
  position: absolute;
}

.custom-link {
  color: var(--text-color);

  &:hover {
    @media (hover: hover) {
      html[data-theme="dark"] & {
        filter: brightness(1.3);
      }

      html[data-theme="light"] & {
        filter: brightness(4);
      }
    }
  }
}

html[data-theme="dark"] .logo-light {
  display: inline-block !important;
}

html[data-theme="light"] .logo-dark {
  display: inline-block !important;
}

.slide-in-right {
  animation: slideInFromRight 2s ease-out forwards;
}

.slide-in-left {
  animation: slideInFromLeft 2s ease-out forwards;
}

@keyframes slideInFromRight {
  from {
    transform: translateX(50px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-50px);
  }
  to {
    transform: translateX(0);
  }
}