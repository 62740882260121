$font-family-sans-serif: 'DM Sans', 'Inter', sans-serif;
$body-font-family: $font-family-sans-serif;

$container-max-widths: (
  sm: 700px,
  md: 950px,
  lg: 1150px,
  xl: 1300px,
  xxl: 1420px
);

$navbar-padding-y: 0;
$nav-link-padding-y: 0.9rem;
$navbar-nav-link-padding-y: 0;

$navbar-light-color: var(--text-color);
$navbar-light-hover-color: rgba(var(--text-color), 0.9);

.nav-link {
  cursor: pointer;
}

@media (hover: hover) {
  .nav-link:hover {
    background-color: var(--primary-color);
  }
}

$input-border-radius: 5px;

.btn-primary-outline {
  border: 2px solid var(--text-color) !important;
  background-color: var(--secondary-color) !important;
  color: var(--text-color) !important;
  &:hover {
    @media (hover: hover) {
      background-color: var(--text-color) !important;
      color: var(--primary-color) !important;
    }
  }
}

.btn-primary-selected {
  background-color: var(--text-color) !important;
  color: var(--primary-color) !important;
}