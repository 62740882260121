:root {
  --primary-color: #FDEEB0;
  --secondary-color: #FFFFC7;
  --text-color: #0F080F;
  --secondary-text-color: #211221;
  --brightness-glass-segment: 1.13;
}

[data-theme="dark"] {
  --primary-color: #0F080F;
  --secondary-color: #211221;
  --text-color: #FDEEB0;
  --secondary-text-color: #FFFFC7;
  --brightness-glass-segment: 2.2;
}