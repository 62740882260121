#CybotCookiebotDialog {
  color: var(--text-color) !important;
  // background-color: var(--primary-color) !important;
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, var(--primary-color) 60%) !important;
  backdrop-filter: blur(10px);    
}

.CybotCookiebotDialogBodyButton {

    border: 2px solid var(--text-color) !important;
    background-color: transparent !important;
    color: var(--text-color) !important;
    &:hover {
      @media (hover: hover) {
        background-color: var(--text-color) !important;
        color: var(--primary-color) !important;
      }
    }
}