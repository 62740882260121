.floating-div {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: var(--text-color);
  color: var(--primary-color);
  padding: 10px;
  border-radius: 5px;
  z-index: 10000;

  & .dialog-closed:hover {
    @media (hover: hover) {
      filter: brightness(1.1) contrast(1.05) saturate(1.2) brightness(1.2);
      cursor: pointer;
      transform: scale(1.02);
    }
  }
}


.advise-container {
  &__1 {
    @media (max-width: 992px) {
      height: 5rem;
    }
  }
  &__2 {
    height: 120rem;
    display: flex;
    flex-direction: column;

    @media (max-width: 1199px) {
      height: 100%;
    }

    & .fixtures_container {
      flex: 0 1 auto; /* Crecen dinámicamente */
      max-height: 50%; /* Límite del 50% */
      display: flex;
      flex-direction: column;
    }
  }
  &__3 {
    overflow: hidden;
    // position: sticky;
    // top: 50px;
    z-index: 7000;
    // height: 60px;
    // @media (min-width: 1200px) {
    //   height: 110px;
    //   // top: 58.781px;
    //   @supports (-moz-appearance: none) {
    //     // top: 55.6px; /* Estilos específicos para navegadores WebKit */
    //   }
    // }
    // @media (min-width: 540px) and (max-width: 767px) {
    //   height: 75px;
    //   // top: 50px;
    //   @supports (-moz-appearance: none) {
    //     // top: 55.6px; /* Estilos específicos para navegadores WebKit */
    //   }
    // }
    // @media (min-width: 768px) and (max-width: 1199px) {
    //   height: 75px;
    //   // top: 58.781px;
    //   @supports (-moz-appearance: none) {
    //     // top: 55.6px; /* Estilos específicos para navegadores WebKit */
    //   }
    // }
  }
}

.container-418c4c2a899cb333d2bda27dd66dd622__stand {
  @media (max-width: 1199px) {
    display: flex;
    flex-flow: row !important;
  }
}
